<style lang="scss" scoped>
	ul {
		padding-left: 20px;

		li {
			list-style-type: circle;
			line-height: 1.2;

			& + li {
				margin-top: 10px;
			}
		}
	}

	a,
	a:visited {
		color: $grey;

		&:hover {
			color: $orange;
		}
	}
</style>

<template>
	<section>
		<h1>Services</h1>
		<ul>
			<li>
				Neuinstrumente auf Vermittlung
			</li>
			<li>
				Vermittlung von Restauration und Reparatur für Ihr Instrument
			</li>
			<li>
				Beratung beim Klavierkauf / -verkauf
			</li>
			<li>
				Beratung für Aufstellung und Pflege des Instruments
			</li>
			<li>
				Beratung bei raumakustischen Problemen
			</li>
			<li>
				Verleih von Hilfsmitteln für den Klaviertransport wie z.B. Tragegurte, Rollwagen usw.
			</li>
			<li>Klavierstühle und -bänke</li>
			<li>Klavierleuchten in großer Auswahl</li>
			<li>Zubehör rund ums Klavier: Klavierrollen, Filzgleiter, Untersetzer</li>
		</ul>
	</section>
</template>
